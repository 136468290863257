import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/defaultPage.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/ward-info',
    name: 'ward-info',
    component: HomeView,
    meta : {
      group : ['public']
    }
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: () => import('../views/defaultPage.vue'),
    meta : {
      group : ['public']
    }
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login.vue'),
  //   meta : {
  //     group : ['public']
  //   }
  // },
  {
    path: '/templeschedule',
    name: 'templeSchedule',
    component: () => import('../views/templeSchedule.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/templeschedule/book',
    name: 'bookSchedule',
    component: () => import('../views/bookSched.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/bishopmessages',
    name: 'bishopMessages',
    component: () => import('../views/bishopMessages.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/sundayprogram',
    name: 'sunday-program',
    component: () => import('../views/sundayprogram.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/wardcalendar',
    name: 'ward-calendar',
    component: () => import('../views/wardcalendar.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/calendar',
    name: 'google-calendar',
    component: () => import('../views/tempcalendar.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/announcements',
    name: 'announcements',
    component: () => import('../views/announcements.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/stakeinfo',
    name: 'stakeInfo',
    component: () => import('../views/stakeInfo.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/stakeconference',
    name: 'stakeConf',
    component: () => import('../views/stakeConference.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/privacypolicy',
    name: 'privacy-policy',
    component: () => import('../views/defaultPage.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/termsandcondition',
    name: 'terms-conditions',
    component: () => import('../views/defaultPage.vue'),
    meta : {
      group : ['public']
    }
  },
  {
    path: '/directory',
    name: 'directory',
    component: () => import('../views/directory.vue'),
    meta : {
      group : ['geneva']
    }
  },
  {
    path: '/wards',
    name: 'wards',
    component: () => import('../views/wards.vue'),
    meta : {
      group : ['geneva']
    }
  },
  {
    path: '/handbook',
    name: 'handbook',
    component: () => import('../views/handbook.vue'),
    meta : {
      group : ['geneva']
    }
  },
  {
    path: '/stakecalendar',
    name: 'stakeCalendar',
    component: () => import('../views/stakeCalendar.vue'),
    meta : {
      group : ['geneva']
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
