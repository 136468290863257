export default class Helper {
     /**
     * Adding Cookie
     * @param {String} cname cookies name
     * @param {String} cvalue cookies value
     * @param {Number} exdays expriation number of days
     */
     static  setCookie(cname, cvalue, exdays = 1) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    /**
     * Getting a cookie
     * @param {String} cname cookie name
     * @returns {String} cookie data
     */
    static getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    /**
     * Delete all cookies
     */
    static deleteAllCookies() {
        var cookies = document.cookie.split(";");
    
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    /**
     * Deleting specific cookie
     * @param {String} cname cookie name 
     */
    static deleteCookie(cname) {
        document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
    /**
     * Get device type or name
     */
    static getdevicetype(type = 'device') {
        let deviceName = '';
        // Device type
        const isMobile = {
            Android: () => {
                return navigator.userAgent.match(/Android/i);
            },
            Datalogic: () => {
                return navigator.userAgent.match(/DL-AXIS/i);
            },
            Bluebird: () => {
                return navigator.userAgent.match(/EF500/i);
            },
            Honeywell: () => {
                return navigator.userAgent.match(/CT50/i);
            },
            Zebra: () => {
                return navigator.userAgent.match(/TC70|TC55/i);
            },
            BlackBerry: () => {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: () => {
                return navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/i);
            },
            Windows: () => {
                return navigator.userAgent.match(/IEMobile/i);
            },
            any: () => {
                return (isMobile.Datalogic() || isMobile.Bluebird() || isMobile.Honeywell() || isMobile.Zebra() || isMobile.BlackBerry() || isMobile.Android() || isMobile.iOS() || isMobile.Windows());
            }
        }


        if (isMobile.Datalogic())
            deviceName = 'Datalogic';
        else if (isMobile.Bluebird())
            deviceName = 'Bluebird';
        else if (isMobile.Honeywell())
            deviceName = 'Honeywell';
        else if (isMobile.Zebra())
            deviceName = 'Zebra';
        else if (isMobile.BlackBerry())
            deviceName = 'BlackBerry';
        else if (isMobile.iOS())
            deviceName = 'iOS';
        else if ((deviceName == '') && (isMobile.Android()))
            deviceName = 'Android';
        else if ((deviceName == '') && (isMobile.Windows()))
            deviceName = 'Windows';

        
        // Browser name
        let browsername = '';

        const browser = {
            chrome : () => {
                return navigator.userAgent.match(/Chrome/i)
            },
            firefox : () => {
                return navigator.userAgent.match(/Firefox/i)
            },
            safari : () => {
                return navigator.userAgent.match(/Safari/i)
            },
            ie : () => {
                return navigator.userAgent.match(/MSIE|Trident/i)
            }
        }

        if(browser.chrome) {
            browsername = 'Chrome'
        } else if(browser.safari) {
            browsername = 'Safari'
        } else if(browser.firefox) {
            browsername = 'Firefox'
        } else if(browser.ie) {
            browsername = "Internet Explorer"
        }

        let result = deviceName

        if(type == 'device') {
            result =  deviceName
        } else {
            result =  browsername
        }

        return result;

    }

    /* Generate Random String */
    static makeString(length) {
        let   result     = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        const charactersLength = characters.length;

        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
       return result;
    }

}