export default class Api {
    static async fetch({
        url,
        params,
        method
    }) {
        /* Process options */
       const options = {
        method : method,
       }

        /* 
            Process params
        */
       const baseurl = process.env.VUE_APP_APIBASEURL
       const newparams = new FormData()

       if(params) {
            for (const key in params) {
                const value = params[key];

                newparams.append(key, value)
            }

            options.body = newparams
       }

      


        // Process call
        const res = await fetch(`${baseurl}/${url}`, options)

        return res.json()
    }
}