import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

/* Global Declerations */
// plugins
import './plugins'
/* Filters */
import './filters'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


/* remove console in production */
console.log(process.env.NODE_ENV)

if(process.env.NODE_ENV != 'development') {
  console.log = () => {}
}